import * as React from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/grid";
import { Box, Flex, Image } from "../../../assets/styles/common";
import { logos } from "./constants";

const Partners = () => {
    return (
        <Box width="100%" px={[0, 2, 4]} py={[2]}>
            <Flex alignItems="center" justifyContent="space-between" flexWrap={{ _: "wrap", "2xl": "nowrap" }}>
                {logos.map(({ Logo, height, alt, ...rest }, i) => (
                    <Flex
                        key={i}
                        py={[5, 5, 4]}
                        px={[4, 4, 2]}
                        justifyContent="center"
                        opacity={0.7}
                        minWidth={["40%", "50%", "50%", `25%`, `20%`, `20%`, "0"]}
                        maxWidth={["60%", "50%"]}
                        {...rest}
                    >
                        <Image src={Logo} w={["auto"]} h={[height]} maxHeight={height} alt={alt} />
                    </Flex>
                ))}
            </Flex>
        </Box>
    );
};
export default Partners;
