import { useCallback, useRef, useState } from "react";
import { urls } from "../../../constants/urls";
import { financingTypes, financingTypesMap, initialValues } from "./constants";

const validationMsg = "Mindestens 50.000 €";

export const useForm = () => {
    const editRef = useRef({});
    const [isUmschuldung, setIsUmschuldung] = useState();
    const [errors, setErrors] = useState({});

    const validatePrice = useCallback(
        (price) => {
            const curr = !!errors.price;
            const hasError = parseInt(price) < 50000;
            if (curr === hasError) return !hasError;
            setErrors((prev) => ({
                ...prev,
                price: hasError ? validationMsg : undefined,
            }));
            return !hasError;
        },
        [errors.price],
    );
    const onPriceChange = ({ value }) => validatePrice(value);

    const onFocus = useCallback((ev) => {
        const el = ev.target;
        const id = el.getAttribute("id");
        if (!editRef.current[id]) {
            el.value = "";
            editRef.current[id] = true;
        }
    }, []);

    const onSubmit = useCallback(
        (ev) => {
            ev.preventDefault();
            const values = Object.fromEntries(new FormData(ev.target));
            const isValid = validatePrice(values.price);
            if (!isValid) return;
            const params = new URLSearchParams({
                ...values,
                financingType: financingTypesMap[values.financingType],
                component: "landing-header",
            });
            window.open(`${urls.survey}?${params}`, "_self");
        },
        [validatePrice],
    );

    const onSelectChange = (ev) => {
        ev.target.setAttribute("data-is-default", false);
    };

    const onFinancingTypeChange = (ev) => {
        const value = ev.target.value;
        onSelectChange(ev);
        setIsUmschuldung(value === financingTypes[3].value);
    };

    return {
        isUmschuldung,
        errors,
        onPriceChange,
        onFocus,
        onSubmit,
        onSelectChange,
        onFinancingTypeChange,
        initialValues: {
            ...initialValues,
            ...Object.fromEntries(new URLSearchParams(typeof window !== "undefined" ? window.location.search : "")),
        },
    };
};
