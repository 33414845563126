import React from "react";
import austriaTrust from "../../assets/images/home/austria-trust.svg";
import { BorderBox, Box, Flex, Image, Text } from "../../assets/styles/common";
import GoogleReviewsElement from "../GoogleReviewsElement";
import Banner from "./Banner";
import CalculateForm from "./CalculateForm";
import Partners from "./Partners";

const BlogHeader = () => {
    return (
        <BorderBox>
            <Banner />
            <Flex sx={formWrapperSx}>
                <Flex sx={formInnerWrapperSx}>
                    <Box sx={formBoxWrapperSx}>
                        <Box sx={formBoxSx}>
                            <CalculateForm />
                            <Text sx={formBottomTextSx}>
                                Ab{" "}
                                <Text as="span" color="primary">
                                    <strong>3,45 %</strong>
                                </Text>{" "}
                                p.a. für <strong>6 Jahre Fix</strong>
                            </Text>
                            <Text sx={formBottomText2Sx}>
                                Ab{" "}
                                <Text as="span" color="primary">
                                    <strong>3,65 %</strong>
                                </Text>{" "}
                                p.a. für <strong>30 Jahre Fix</strong>
                            </Text>
                        </Box>
                    </Box>
                </Flex>
                <Flex sx={trustWrapperSx}>
                    <Box flexGrow={1} maxWidth="60px">
                        <Image src={austriaTrust} width={44} height={29} alt="Austria trust" />
                    </Box>
                    <Box flexGrow={1} maxWidth="107px">
                        <GoogleReviewsElement />
                    </Box>
                    <Text flexGrow={1} maxWidth="130px" sx={trustTextSx}>
                        Nr. 1 in Kundenzufriedenheit
                    </Text>
                </Flex>
                <Box sx={partnersWrapper}>
                    <Flex sx={partnersInnerWrapper}>
                        <Partners />
                    </Flex>
                </Box>
            </Flex>
        </BorderBox>
    );
};
export default BlogHeader;

const formWrapperSx = {
    position: "relative",
    flexDirection: "column",
    zIndex: 2,
};
const formInnerWrapperSx = {
    flexOrder: [0, 0, 1],
    bg: ["#fff", null, "#EEEEF0"],
    zIndex: 2,
};
const formBoxWrapperSx = {
    position: { xl: "absolute" },
    width: "100%",
    px: [5],
    mt: ["-200px", "-75px", "-92px"],
};
const formBoxSx = {
    width: "100%",
    maxWidth: "1440px",
    mx: "auto",
    bg: "white",
    borderRadius: "10px",
    p: [4, 5, 7, 8],
    boxShadow: "0px 10px 60px -2px rgba(0, 0, 0, 0.2), 0px 2px 12px -4px rgba(0, 0, 0, 0.18)",
};
const formBottomTextSx = {
    display: ["block", null, null, "none"],
    width: "100%",
    textAlign: "center",
    fontSize: "sm",
    color: "gray.700",
    mt: 4,
};
const formBottomText2Sx = {
    display: ["block", null, null, "none"],
    width: "100%",
    textAlign: "center",
    fontSize: "sm",
    color: "gray.700",
    mt: 1,
};
const trustWrapperSx = {
    display: ["flex", null, "none"],
    px: 5,
    py: 9,
    columnGap: [5, 7],
    alignItems: "center",
    justifyContent: "space-between",
};
const trustTextSx = {
    fontSize: "xs",
    color: "gray.500",
    fontWeight: "500",
};
const partnersWrapper = {
    flexOrder: [1, 1, 0],
    bg: "#FFF",
};
const partnersInnerWrapper = {
    pb: 2,
    pt: [10, null, null, null, null, 15],
};
