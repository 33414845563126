import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import { Button, CenterBlock, ContentWrapper } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";
import { colors } from "../constants/colors";
import GtmService from "../service/gtmService";
import Title from "./Title";

/**
 * Components for rendering FAQs
 * @param useH1 set to indicate h1 title rendering
 * @param {string} faqResource defines the string translation resource for the faqs to be rendered, defaults to "faq.sections",
 * only pass a valid structured faq resource, such as the faqs in the {@link faq.json} file
 * @param {boolean} renderStructuredData set to true to render structured data for the faq, defaults to false.
 * @returns {JSX.Element}
 * @constructor
 */
const Faq = ({
    useH1,
    faqResource = "faq.sections",
    shouldRenderStructuredData = false,
    ctaText,
    ctaUrl,
    hasMarginTop,
}) => {
    const { t } = useTranslation();

    /* Pre-define the FAQ structured-data schema, which is used to generate the JSON-LD */
    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [],
    };

    let lastClickedItem = null;

    const handleQuestionClick = (e) => {
        let answer = null;

        if (!lastClickedItem) {
            lastClickedItem = document.querySelector("dt.isOpen");
        }

        answer = lastClickedItem.nextSibling;

        if (lastClickedItem && lastClickedItem !== e.target) {
            lastClickedItem.classList.remove("isOpen");
            answer.style.maxHeight = "0";
        }

        lastClickedItem = e.target;
        answer = lastClickedItem.nextSibling;

        if (lastClickedItem.classList.contains("isOpen")) {
            lastClickedItem.classList.remove("isOpen");
            answer.style.maxHeight = 0;
        } else {
            lastClickedItem.classList.add("isOpen");
            answer.style.maxHeight = answer.scrollHeight + "px";
        }
    };

    const sections = t(faqResource, { returnObjects: true });

    return (
        <StyledContentWrapper hasMarginTop={hasMarginTop}>
            <Title useH1={useH1}>
                <Trans>faq.title</Trans>
            </Title>

            {sections.map((section, sectionKey) => {
                return (
                    <React.Fragment key={sectionKey}>
                        <h3 className="h3">{section.title}</h3>
                        <dl>
                            {section.qa.map((qa, questionKey) => {
                                /* Build up the faq json+ld element */
                                if (shouldRenderStructuredData) {
                                    faqSchema.mainEntity.push({
                                        "@type": "Question",
                                        name: qa[0],
                                        acceptedAnswer: {
                                            "@type": "Answer",
                                            text: qa[1],
                                        },
                                    });
                                }

                                /* Return the FAQ elements */
                                return (
                                    <React.Fragment key={questionKey}>
                                        <dt
                                            className={!sectionKey && !questionKey ? "isOpen" : ""}
                                            onClick={(e) => {
                                                GtmService.instance.trackEvent({
                                                    event: "faqSectionClick",
                                                    question: qa[0],
                                                });

                                                handleQuestionClick(e);
                                            }}
                                            role="presentation"
                                        >
                                            {qa[0]}
                                        </dt>
                                        <dd
                                            style={{
                                                maxHeight: !sectionKey && !questionKey ? "none" : 0,
                                            }}
                                        >
                                            {qa[1]}
                                        </dd>
                                    </React.Fragment>
                                );
                            })}
                        </dl>
                    </React.Fragment>
                );
            })}

            <CenterBlock>
                <a href={ctaUrl}>
                    <Button>{ctaText}</Button>
                </a>
            </CenterBlock>

            {shouldRenderStructuredData && (
                <Helmet>
                    {Object.keys(faqSchema).length && (
                        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
                    )}
                </Helmet>
            )}
        </StyledContentWrapper>
    );
};

const StyledContentWrapper = styled(ContentWrapper)`
    font-size: 14px;

    @media (min-width: ${breakpoints.tabletMin}) {
        font-size: 16px;
    }

    dl {
        margin: 0 0 50px;
    }
    dt {
        border-bottom: 1px solid ${colors.primary};
        color: ${colors.primary};
        cursor: pointer;
        padding: 14px 30px 14px 0;
        position: relative;

        :after {
            color: ${colors.primary};
            content: "+";
            font-size: 20px;
            font-weight: 500;
            line-height: 1;
            margin-top: -10px;
            position: absolute;
            right: 3px;
            top: 50%;
        }

        @media (min-width: ${breakpoints.tabletMin}) {
            padding-bottom: 20px;
            padding-top: 20px;
        }

        &.isOpen {
            :after {
                content: "-";
            }
            + dd {
                border-bottom: 1px solid ${colors.primary};
                padding: 14px 0;

                @media (min-width: ${breakpoints.tabletMin}) {
                    padding-bottom: 20px;
                    padding-top: 20px;
                }
            }
        }
    }
    dd {
        margin: 0;
        max-height: 0;
        padding: 0;
        overflow: hidden;
        transition: max-height 0.25s ease-out, padding 0.25s ease-out;
    }
`;

export default Faq;
