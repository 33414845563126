import React, { useRef } from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import FaqContent from "../components/Faq";
import styled from "styled-components";
import { ContentWrapper } from "../assets/styles/base";
import arrowRight from "../assets/icons/arrow-right-blog.svg";
import FinanzierungsratgeberIcon from "../assets/icons/finanzierungsratgeber-icon.svg";
import ImmobilienratgeberIcon from "../assets/icons/immobilienratgeber-icon.svg";
import ZinsratgeberIcon from "../assets/icons/zinsratgeber-icon.svg";
import AktuelleEntwicklungenIcon from "../assets/icons/aktuelle-entwicklungen-icon.svg";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import BlogHeader from "../components/BlogHeader/index";
import { breakpoints } from "../constants/breakpoints";
import { Flex } from "../assets/styles/common";

import { urls, extendUrlWithSourceVersion } from "../constants/urls";

const Ratgeber = () => {
    const { t } = useTranslation();
    const articlesContainerFinaRef = useRef(null);
    const articlesContainerImoRef = useRef(null);
    const articlesContainerActuelRef = useRef(null);
    const articlesContainerZinsRef = useRef(null);
    const articles = t("page.articles", { returnObjects: true });
    const categories = t("page.articlesCategories", { returnObjects: true });

    const getFilteredArticlesByCategoryIndex = (index) => {
        const filteredArticlesByCat = articles.filter((item) => {
            if (item.category.toLowerCase() === categories[index].articleCategoryName.toLocaleLowerCase()) {
                return item;
            }
        });
        return filteredArticlesByCat;
    };

    const handleScroll = (index) => {
        let ref = getRefByCategoryIndex(index);

        window.scrollTo({
            top: ref.offsetTop - 80,
            left: 0,
            behavior: "smooth",
        });
    };

    const getRefByCategoryIndex = (index) => {
        switch (index) {
            case 0:
                return articlesContainerFinaRef.current;
            case 1:
                return articlesContainerImoRef.current;
            case 2:
                return articlesContainerActuelRef.current;
            default:
                return articlesContainerZinsRef.current;
        }
    };

    const getCategoriesIcon = (index) => {
        switch (index) {
            case 0:
                return <FinanzierungsratgeberIcon className="categoriesFilterIcon" />;
            case 1:
                return <ImmobilienratgeberIcon className="categoriesFilterIcon" />;
            case 2:
                return <ZinsratgeberIcon className="categoriesFilterIcon" />;
            default:
                return <AktuelleEntwicklungenIcon className="categoriesFilterIcon" />;
        }
    };

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <BlogHeader />
            <ContentWrapper>
                <Flex mt={[100]} flexWrap="wrap" display={["block", null, null, "flex"]}>
                    {categories.map((item, index) => {
                        return (
                            <TopCategoriesFilter
                                key={"articles-categories-" + index}
                                onClick={() => {
                                    handleScroll(index);
                                }}
                            >
                                {getCategoriesIcon(index)}
                                <h3>{item.name}</h3>
                                <p>{item.description}</p>
                                <div className="arrowConainer">
                                    <span>
                                        alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                                    </span>
                                </div>
                            </TopCategoriesFilter>
                        );
                    })}
                </Flex>
                <Flex flexDirection="row" flexWrap="wrap" mt={[100]}>
                    <Flex flexDirection="column" flex={[1]} justifyContent="center">
                        {getCategoriesIcon(0)}
                        <h3>{categories[0].name}</h3>
                        <div className="arrowConainer">
                            <span>
                                alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                            </span>
                        </div>
                    </Flex>
                    <Flex flexDirection="column" flex={[1]}>
                        <h2>{categories[0].name}</h2>
                        <p>
                            Bevor du den{" "}
                            <Link to="/artikel/kreditvertrag/" target="_blank" rel="noopener noreferrer">
                                Kreditvertrag
                            </Link>{" "}
                            für deine{" "}
                            <Link to="/artikel/hausfinanzierung/" target="_blank" rel="noopener noreferrer">
                                Hausfinanzierung
                            </Link>{" "}
                            oder deinen{" "}
                            <Link
                                to="/artikel/10-tipps-fur-den-wohnungskauf/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Wohnungskauf
                            </Link>{" "}
                            abschließt, bevor du das Verhandlungsgespräch mit deiner Bank beginnst, ja bevor du dich
                            überhaupt von einem Kreditinstitut zu einem{" "}
                            <Link to="/artikel/darlehen-und-kredit/" target="_blank" rel="noopener noreferrer">
                                Darlehen
                            </Link>{" "}
                            beraten lässt, kannst du dich selbst über die wichtigsten Phänomene, Begriffe, Regelungen
                            und Richtlinien informieren – mit dem Finanzierungsratgeber von miracl. Bei uns findest du
                            nicht nur klare und prägnante Definitionen und essenzielle Informationen, sondern auch
                            alltagsnahe Beispiele und hilfreiche Tipps rund um die Themen{" "}
                            <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noopener noreferrer">
                                Immobilienfinanzierung
                            </Link>
                            ,{" "}
                            <Link to="/artikel/bauen/" target="_blank" rel="noopener noreferrer">
                                Bauen
                            </Link>
                            , Mieten, Kaufen und{" "}
                            <Link to="/artikel/mietkauf/" target="_blank" rel="noopener noreferrer">
                                Mietkauf
                            </Link>
                            : Bei uns werden nicht nur Konzepte wie{" "}
                            <Link to="/artikel/agio/" target="_blank" rel="noopener noreferrer">
                                Adgio
                            </Link>
                            ,{" "}
                            <Link to="/artikel/annuitaetendarlehen/" target="_blank" rel="noopener noreferrer">
                                Annuitätendarlehen
                            </Link>
                            ,{" "}
                            <Link to="/artikel/bonitaet/" target="_blank" rel="noopener noreferrer">
                                Bonität
                            </Link>
                            ,{" "}
                            <Link to="/artikel/einlagensicherung/" target="_blank" rel="noopener noreferrer">
                                Einlagensicherung
                            </Link>
                            ,{" "}
                            <Link to="/artikel/tilgung/" target="_blank" rel="noopener noreferrer">
                                Tilgung
                            </Link>{" "}
                            und{" "}
                            <Link to="/artikel/zwischensfinanzierung/" target="_blank" rel="noopener noreferrer">
                                Zwischenfinanzierung
                            </Link>{" "}
                            erläutert. Wie helfen dir außerdem dabei, deine{" "}
                            <Link
                                to="/artikel/haushaltsrechnung-schritt-fuer-schritt/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Haushaltsrechnung
                            </Link>{" "}
                            zu erstellen, geben dir{" "}
                            <Link to="/artikel/kredit-tipps/" target="_blank" rel="noopener noreferrer">
                                10 Ratschläge für die Vorbereitung auf deinen Kredit
                            </Link>{" "}
                            und zeigen dir, wie du in{" "}
                            <Link to="/artikel/wohnungsfinanzierung/" target="_blank" rel="noopener noreferrer">
                                10 Schritten zur Wohnungsfinanzierung
                            </Link>{" "}
                            kommst. Ist es möglich, eine{" "}
                            <Link
                                to="/artikel/finanzierung-ohne-eigenkapital/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Finanzierung ohne Eigenkapital
                            </Link>{" "}
                            zu erhalten? Kann ich meinen Kredit vorzeitig zurückzahlen und welche{" "}
                            <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noopener noreferrer">
                                Nebenkosten
                            </Link>{" "}
                            entstehen dadurch? Ist{" "}
                            <Link to="/artikel/eigentum/" target="_blank" rel="noopener noreferrer">
                                Eigentum
                            </Link>{" "}
                            nach wie vor die rentabelste Wohnlösung?{" "}
                            <Link to="/artikel/fremdwaehrungskredite/" target="_blank" rel="noopener noreferrer">
                                Sind Fremdwährungskredit noch eine Option
                            </Link>
                            ? Und was kann man tun, wenn der{" "}
                            <Link to="/artikel/kredit-abgelehnt/" target="_blank" rel="noopener noreferrer">
                                Kredit abgelehnt
                            </Link>{" "}
                            wurde? Das sind nur einige der brennenden Fragen, die wir in unserem Finanzierungsratgeber
                            beantworten.
                        </p>
                    </Flex>
                </Flex>
                <ArticlesListContainer mt={[100]} flexWrap="wrap" display={["block", "block", "block", "flex"]}>
                    <Flex flexDirection="column" my={[10]} flex={[1]}>
                        <h2>Inhaltsverzeichnis: {categories[0].name}</h2>
                        <ArticlesList ref={articlesContainerFinaRef}>
                            {getFilteredArticlesByCategoryIndex(0).map((item, index) => {
                                return (
                                    <ArticleContainer key={"article-" + index}>
                                        <h3>
                                            <Link to={item.link} target="_blank" rel="noopener noreferrer">
                                                {item.heading}
                                            </Link>
                                        </h3>
                                    </ArticleContainer>
                                );
                            })}
                        </ArticlesList>
                    </Flex>
                    <SideCategoriesFilter flexDirection="column" my={[10]} flex={[1]}>
                        {categories.map((item, index) => {
                            return index !== 0 ? (
                                <SideCategoriesFilterItem
                                    key={"article-filter-" + index}
                                    onClick={() => {
                                        handleScroll(index);
                                    }}
                                >
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                    <div className="arrowConainer">
                                        <span>
                                            alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                                        </span>
                                    </div>
                                </SideCategoriesFilterItem>
                            ) : null;
                        })}
                    </SideCategoriesFilter>
                </ArticlesListContainer>
                <Flex flexDirection="row" flexWrap="wrap" mt={[100]}>
                    <Flex flexDirection="column" flex={[1]} justifyContent="center">
                        {getCategoriesIcon(1)}
                        <h3>{categories[1].name}</h3>
                        <div className="arrowConainer">
                            <span>
                                alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                            </span>
                        </div>
                    </Flex>
                    <Flex flexDirection="column" flex={[1]}>
                        <h2>{categories[1].name}</h2>
                        <p>
                            Welche{" "}
                            <Link to="/artikel/baunebenkosten/" target="_blank" rel="noopener noreferrer">
                                Baunebenkosten
                            </Link>{" "}
                            erwarten mich? Zahlt es sich aus,{" "}
                            <Link
                                to="/artikel/immobilien-kaufen-und-vermieten/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Immobilien zu kaufen und zu vermieten
                            </Link>
                            ? Sollte ich überhaupt ein Haus oder eine Wohnung{" "}
                            <Link to="/artikel/kauf-oder-miete/" target="_blank" rel="noopener noreferrer">
                                kaufen oder ist Mieten
                            </Link>{" "}
                            die grundsätzlich bessere, weil flexiblere Option? Soll ich{" "}
                            <Link to="/artikel/bauen-oder-kaufen/" target="_blank" rel="noopener noreferrer">
                                bauen oder kaufen
                            </Link>
                            ? Was muss ich beim{" "}
                            <Link to="/artikel/kaufvertrag/" target="_blank" rel="noopener noreferrer">
                                Kaufvertrag
                            </Link>{" "}
                            beachten? Was ist eigentlich der{" "}
                            <Link to="/artikel/grundsteuer/" target="_blank" rel="noopener noreferrer">
                                Unterschied zwischen Grundsteuer und Grunderwerbsteuer
                            </Link>
                            ? Was deckt eine{" "}
                            <Link to="/artikel/gebaudeversicherung/" target="_blank" rel="noopener noreferrer">
                                Gebäudeversicherung
                            </Link>
                            , was eine{" "}
                            <Link to="/artikel/haushaltsversicherung/" target="_blank" rel="noopener noreferrer">
                                Haushaltsversicherung
                            </Link>
                            ? Und gibt es einen Ort, an dem ich die Antworten auf all diese Fragen finde?
                        </p>
                        <p>
                            Diesen Ort haben wir mit dem Immobilienratgeber von miracl geschaffen. Unsere
                            Immobilienfinanzierungsexpert:innen haben hier alle Informationen zusammengetragen, die du
                            vor, während und nach dem Kaufprozess benötigst. Von grundlegenden Definitionen wie{" "}
                            <Link to="/artikel/grundbuch-kosten/" target="_blank" rel="noopener noreferrer">
                                Grundbuch
                            </Link>
                            ,{" "}
                            <Link to="/artikel/grunderwerbsteuer/" target="_blank" rel="noopener noreferrer">
                                Grunderwerbsteuer
                            </Link>{" "}
                            und{" "}
                            <Link to="/artikel/kaufanbot/" target="_blank" rel="noopener noreferrer">
                                Kaufanbot
                            </Link>{" "}
                            über detaillierte Kostenaufstellungen für Renovierung,{" "}
                            <Link to="/artikel/sanierung/" target="_blank" rel="noopener noreferrer">
                                Sanierung
                            </Link>
                            ,{" "}
                            <Link to="/artikel/steuern-beim-immobilienkauf/" target="_blank" rel="noopener noreferrer">
                                Steuern
                            </Link>{" "}
                            und Gebühren bis hin zu konkreten Tipps zum Thema{" "}
                            <Link to="/artikel/besichtigunstermin/" target="_blank" rel="noopener noreferrer">
                                Besichtigung
                            </Link>
                            ,{" "}
                            <Link to="/artikel/hauskauf/" target="_blank" rel="noopener noreferrer">
                                Hauskauf
                            </Link>
                            ,{" "}
                            <Link
                                to="/artikel/10-tipps-fur-den-wohnungskauf/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Wohnungskauf
                            </Link>
                            ,{" "}
                            <Link to="/artikel/umzug/" target="_blank" rel="noopener noreferrer">
                                Umzug
                            </Link>{" "}
                            und{" "}
                            <Link to="/artikel/umbau/" target="_blank" rel="noopener noreferrer">
                                Umbau
                            </Link>{" "}
                            wirst du hier alles finden, was du brauchst, um für das Verhandlungsgespräch mit deiner Bank
                            bestens vorbereitet zu sein und die besten Konditionen zu erhalten.
                        </p>
                    </Flex>
                </Flex>
                <ArticlesListContainer mt={[100]} flexWrap="wrap" display={["block", "block", "block", "flex"]}>
                    <Flex flexDirection="column" my={[10]} flex={[1]}>
                        <h2>Inhaltsverzeichnis: {categories[1].name}</h2>
                        <ArticlesList ref={articlesContainerImoRef}>
                            {getFilteredArticlesByCategoryIndex(1).map((item, index) => {
                                return (
                                    <ArticleContainer key={"article-" + index}>
                                        <h3>
                                            <Link to={item.link} target="_blank" rel="noopener noreferrer">
                                                {item.heading}
                                            </Link>
                                        </h3>
                                    </ArticleContainer>
                                );
                            })}
                        </ArticlesList>
                    </Flex>
                    <SideCategoriesFilter flexDirection="column" my={[10]} flex={[1]}>
                        {categories.map((item, index) => {
                            return index !== 1 ? (
                                <SideCategoriesFilterItem
                                    key={"article-filter-" + index}
                                    onClick={() => {
                                        handleScroll(index);
                                    }}
                                >
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                    <div className="arrowConainer">
                                        <span>
                                            alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                                        </span>
                                    </div>
                                </SideCategoriesFilterItem>
                            ) : null;
                        })}
                    </SideCategoriesFilter>
                </ArticlesListContainer>
                <Flex flexDirection="row" flexWrap="wrap" mt={[100]}>
                    <Flex flexDirection="column" flex={[1]}>
                        {getCategoriesIcon(2)}
                        <h3>{categories[2].name}</h3>
                        <div className="arrowConainer">
                            <span>
                                alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                            </span>
                        </div>
                    </Flex>
                    <Flex flexDirection="column" flex={[1]}>
                        <h2>{categories[2].name}</h2>
                        <p>
                            Auf dem Markt geht es so theatralisch wie gespenstisch zu: Unsichtbare Hände vermitteln
                            zwischen dem Interesse von Einzelperson und Bevölkerung, das Verhältnis von Angebot und
                            Nachfrage gestalt sich mal als Tragödie, mal als Farce und das Wirtschaftssystem als solches
                            findet Halt gerade in seiner Haltlosigkeit und Krisenanfälligkeit. Aufseiten jener
                            Regelungen, Richtlinien und Gesetze, die für (zukünftige) Kreditnehmer:innen relevant sind,
                            bedeutet das: ständige Änderungen und Neuerungen und somit auch ständige Recherche und
                            Revision der eigenen Pläne. Grund genug, eine Plattform zu schaffen, auf der
                            Kreditnehmer:innen alle aktuellen Entwicklungen auf dem Immobilien- und Finanzmarkt
                            verfolgen und erfahren können, welche Auswirkungen diese auf ihre Finanzierung haben. Was
                            ändert sich mit den{" "}
                            <Link to="/artikel/kreditregeln/" target="_blank" rel="noopener noreferrer">
                                neuen Keditregeln
                            </Link>
                            ? Wie steht es derzeit um{" "}
                            <Link to="/artikel/kreditzinsen-entwicklung/" target="_blank" rel="noopener noreferrer">
                                Kreditzinsen
                            </Link>
                            ? Wie wirken sich Coronapandemie und Ukrainekrieg auf den Immobilien- und Kreditmarkt aus?
                            Ist die Angst vor einer{" "}
                            <Link to="/artikel/immobilienblase/" target="_blank" rel="noopener noreferrer">
                                Immobilienblase
                            </Link>{" "}
                            berechtigt? Und{" "}
                            <Link
                                to="/artikel/was-kostet-eine-wohnung-in-oesterreich/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                was kostet eine Wohnung
                            </Link>
                            ,{" "}
                            <Link
                                to="/artikel/was-kostet-ein-haus-in-oesterreich/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                was kostet ein Haus
                            </Link>{" "}
                            in den einzelnen Bundesländern? Diese und weitere Fragen beantworten wir in unserer
                            Ratgeber-Rubrik “Aktuelle Entwicklungen”.
                        </p>
                    </Flex>
                </Flex>
                <ArticlesListContainer mt={[100]} flexWrap="wrap" display={["block", "block", "block", "flex"]}>
                    <Flex flexDirection="column" my={[10]} flex={[1]}>
                        <h2>Inhaltsverzeichnis: {categories[2].name}</h2>
                        <ArticlesList ref={articlesContainerActuelRef}>
                            {getFilteredArticlesByCategoryIndex(2).map((item, index) => {
                                return (
                                    <ArticleContainer key={"article-" + index}>
                                        <h3>
                                            <Link to={item.link} target="_blank" rel="noopener noreferrer">
                                                {item.heading}
                                            </Link>
                                        </h3>
                                    </ArticleContainer>
                                );
                            })}
                        </ArticlesList>
                    </Flex>
                    <SideCategoriesFilter flexDirection="column" my={[10]} flex={[1]}>
                        {categories.map((item, index) => {
                            return index !== 2 ? (
                                <SideCategoriesFilterItem
                                    key={"article-filter-" + index}
                                    onClick={() => {
                                        handleScroll(index);
                                    }}
                                >
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                    <div className="arrowConainer">
                                        <span>
                                            alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                                        </span>
                                    </div>
                                </SideCategoriesFilterItem>
                            ) : null;
                        })}
                    </SideCategoriesFilter>
                </ArticlesListContainer>
                <Flex flexDirection="row" flexWrap="wrap" mt={[100]}>
                    <Flex flexDirection="column" flex={[1]}>
                        {getCategoriesIcon(3)}
                        <h3>{categories[3].name}</h3>
                        <div className="arrowConainer">
                            <span>
                                alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                            </span>
                        </div>
                    </Flex>
                    <Flex flexDirection="column" flex={[1]}>
                        <h2>{categories[3].name}</h2>
                        <p>
                            Zinsen sind für Effizienz wie Krisenanfälligkeit moderner Wirtschaftssysteme gleichermaßen
                            exemplarisch: Denn ermöglichen sie es einerseits, eine vermeintlich unschuldige Kategorie
                            wie die Zeit in ökonomischer Hinsicht produktiv, ja zu einem wesentlichen Wirtschaftsfaktor
                            zu machen, so entscheidet sich mit der tagesaktuellen Fluktuation von Zinssätzen
                            andererseits nicht nur das Geschick von Einzelpersonen, Unternehmen und Gemeinschaften,
                            sondern zugleich das ganzer Staaten und Gesellschaften.
                        </p>
                        <p>
                            Unser Ratgeber widmet sich diesem Thema daher mit großer Sorgfalt und Genauigkeit. In den
                            Artikeln dieser Rubrik findest du – neben einem prägnanten{" "}
                            <Link to="/artikel/zinsen/" target="_blank" rel="noopener noreferrer">
                                Überblick
                            </Link>{" "}
                            zum Thema – Erläuterungen grundlegender Begriffe wie{" "}
                            <Link to="/artikel/inflation/" target="_blank" rel="noopener noreferrer">
                                Inflation
                            </Link>
                            ,{" "}
                            <Link to="/artikel/deflation/" target="_blank" rel="noopener noreferrer">
                                Deflation
                            </Link>
                            ,{" "}
                            <Link to="/artikel/leitzins/" target="_blank" rel="noopener noreferrer">
                                Leitzins
                            </Link>
                            ,{" "}
                            <Link to="/artikel/negativzins/" target="_blank" rel="noopener noreferrer">
                                Negativzins
                            </Link>
                            ,{" "}
                            <Link to="/artikel/euribor/" target="_blank" rel="noopener noreferrer">
                                EURIBOR
                            </Link>{" "}
                            und{" "}
                            <Link to="/artikel/zinscap/" target="_blank" rel="noopener noreferrer">
                                Zinscap
                            </Link>{" "}
                            sowie umfassende Informationen zu{" "}
                            <Link to="/artikel/bauzinsen/" target="_blank" rel="noopener noreferrer">
                                Bauzinsen
                            </Link>
                            .
                        </p>
                    </Flex>
                </Flex>
                <ArticlesListContainer mt={[100]} flexWrap="wrap" display={["block", "block", "block", "flex"]}>
                    <Flex flexDirection="column" my={[10]} flex={[1]}>
                        <h2>Inhaltsverzeichnis: {categories[3].name}</h2>
                        <ArticlesList ref={articlesContainerZinsRef}>
                            {getFilteredArticlesByCategoryIndex(2).map((item, index) => {
                                return (
                                    <ArticleContainer key={"article-" + index}>
                                        <h3>
                                            <Link to={item.link} target="_blank" rel="noopener noreferrer">
                                                {item.heading}
                                            </Link>
                                        </h3>
                                    </ArticleContainer>
                                );
                            })}
                        </ArticlesList>
                    </Flex>
                    <SideCategoriesFilter flexDirection="column" my={[10]} flex={[1]}>
                        {categories.map((item, index) => {
                            return index !== 3 ? (
                                <SideCategoriesFilterItem
                                    key={"article-filter-" + index}
                                    onClick={() => {
                                        handleScroll(index);
                                    }}
                                >
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                    <div className="arrowConainer">
                                        <span>
                                            alle Artikel anzeigen <ArrowRightStyled width={28} height={21} />
                                        </span>
                                    </div>
                                </SideCategoriesFilterItem>
                            ) : null;
                        })}
                    </SideCategoriesFilter>
                </ArticlesListContainer>
            </ContentWrapper>
            <FaqContent
                useH1
                shouldRenderStructuredData={false}
                ctaText="Kostenlose Beratung"
                ctaUrl={extendUrlWithSourceVersion(urls.survey)}
                hasMarginTop
            />
            <BreadcrumbList page={"ratgeber"}></BreadcrumbList>
        </Layout>
    );
};

const TopCategoriesFilter = styled.div`
    padding: 15px 10px;
    cursor: pointer;
    flex: 1;
    h3 {
        font-size: 18px;
        font-weight: 500;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #374151;
        white-space: pre-wrap;
    }
    .arrowConainer {
        display: flex;
        span {
            color: #111827;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            svg {
                align-self: center;
                padding: 0 15px;
            }
        }
    }
    @media (max-width: ${breakpoints.mobileBigMax}) {
        padding: 15px 0px;
        .categoriesFilterIcon {
            display: none;
        }
    }
`;

const SideCategoriesFilter = styled(Flex)`
    padding-left: 10px;
    .arrowConainer {
        display: flex;
        span {
            display: flex;
            svg {
                align-self: center;
                padding: 0 15px;
            }
        }
    }
    @media (max-width: ${breakpoints.mobileBigMax}) {
        h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #111827;
        }
        p {
            display: none;
        }
    }
`;

const SideCategoriesFilterItem = styled.div`
    cursor: pointer;
    margin-bottom: 30px;
    p {
        white-space: pre-wrap;
    }
    @media (max-width: ${breakpoints.mobileBigMax}) {
        h3 {
            margin-bottom: 5px;
        }
    }
`;

const ArticlesListContainer = styled(Flex)`
    h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #111827;
    }
    @media (max-width: ${breakpoints.mobileBigMax}) {
        h2 {
            font-size: 20px;
        }
    }
`;
const ArticlesList = styled.div``;

const ArticleContainer = styled.article`
    margin-bottom: 25px;
    h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #4b5563;
        margin-bottom: 0px;
        a {
            color: #4b5563;
        }
    }
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #4b5563;
    }
`;

const ArrowRightStyled = styled(arrowRight)`
    width: 28px;
`;

export default Ratgeber;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "faq", "page.ratgeber"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
